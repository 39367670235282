<template>
    <div>
        <b-col>
        <b-row>
            <b-col class="no-padding">
                <b-form-select v-model="secilenBolge" :options="bolgeler" class="no-radius"></b-form-select>
            </b-col>
            <b-col class="no-padding">
                <b-form-select v-model="secilenBelediye" :options="belediyeler" class="no-radius"></b-form-select>
            </b-col>
        </b-row>
        </b-col>
        <gmap-map
            :center="center"
            :zoom="12"
            style="width:100vw;  height: calc(100vh - 90px);"
            :options="{disableDefaultUI:false}"
            v-if="markers"
            @click="infoKapat()">
            <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m"
                :icon="m.data.sure <= 0 ? dots.red : dots.green"
                @click="popupAc(index)">
                <gmap-info-window
                    :options="infoOptions"
                    :position="infoPosition"
                    :opened="m.info"
                    :content="infoContent"
                    @closeclick="m.info=false">
                    <div style="width: 200px;">
                        <div> 
                            <p style="font-weight: 700;font-size: 15px;">
                                ID: <b-badge class="calloutTitle">#{{m.kk_id}}</b-badge>
                            </p>
                        </div>
                        <div style="margin-top: 5px;margin-bottom: 15px;font-size: 14px;"> 
                            <p><b>Adres:</b> {{m.kk_adres}}</p>
                        </div>
                        <div style="margin-top: 5px;margin-bottom: 15px;font-size: 14px;"> 
                            <p><b>Bölge:</b> {{bolgeAdGetir(m.kk_bolge)}}</p>
                            <p class="mb-0"><b>Süre:</b> {{m.kk_gun}} günde bir</p>
                            <p><b>Toplama:</b> {{m.data.sonTarih}}</p>
                        </div>
                    </div>
                </gmap-info-window>
            </gmap-marker>
        </gmap-map>
    </div>
</template>

<script>
export default {
    data(){
        return{
            center: {
                lat: 38.4653076,
                lng: 27.3498951
            },
            dots: {
                green: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                red: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
            },
            secilenBolge: null,
            secilenBelediye: null,
            infoPosition: null,
            infoContent: null,
            infoOpened: true,
            infoCurrentKey: null,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: 0
                }
      
            },
            markers: [],
        }
    },

    methods: {
        addMarker(e){
            console.log(e)
        },

        sil(e){
            this.markers.splice(e, 1)
            console.log(e)
        },
        popupAc(index){
            this.infoKapat();
            this.markers[index].info = true;
        },
        infoKapat(){
            for(var i=0;i<this.markers.length;i++){
                this.markers[i].info = false;
            }
        },
        bolgeAdGetir(bolge_id){
            var index = this.bolgeler.findIndex(c => c.value == bolge_id);
            return index > -1 ? this.bolgeler[index].text : null;

        }
    },

    computed: {
        konteynerlar(){
            return this.$store.state.konteynerlar.filter(c => c.kk_user > 0);
        },

        _markers(){
            var data = null;
            var bolgeler = null;
            if(!this.secilenBolge){
                if(!this.secilenBelediye){
                    data = this.konteynerlar;
                }else{
                    bolgeler = this.$store.state.bolgeler.filter(c => c.bolge_belediye == this.secilenBelediye).map(c => {
                        return c.bolge_id;
                    });
                    data = this.konteynerlar.filter(e => {
                            if(bolgeler.includes(e.kk_bolge)){
                                return e;
                            }
                        }
                    );
                }
            }else{
                if(!this.secilenBelediye){
                    data = this.konteynerlar.filter(r => r.kk_bolge == this.secilenBolge);
                }else{
                    //this.secilenBolge = null;
                    bolgeler = this.$store.state.bolgeler.filter(c => c.bolge_belediye == this.secilenBelediye).map(c => {
                        return c.bolge_id;
                    });
                    data = this.konteynerlar.filter(e => {
                            if(bolgeler.includes(e.kk_bolge)){
                                return e;
                            }
                        }
                    );
                }
            }

            return data.map(c => {
                return {
                    lat: Number(c.kk_lat),
                    lng: Number(c.kk_lng),
                    kk_id: c.kk_id,
                    kk_bolge: c.kk_bolge,
                    kk_gun: c.kk_gun,
                    order: c.kk_order,
                    info: false,
                    data: c.data,
                    kk_adres: c.kk_adres,
                }
            });    
        },
        bolgeler(){
            var data = [{
                value: null,
                text: "Bölge Seçin"
            }];
            this.$store.state.bolgeler.map(c => {
                var bolge = {
                    value: c.bolge_id,
                    text: c.bolge_ad,
                }
                data.push(bolge);
                return bolge;
            });
            return data;
        },

        belediyeler(){
            var data = [{
                value: null,
                text: "Belediye Seçin"
            }];
            this.$store.state.users.filter(r => r.user_rutbe == 2).map(c => {
                var belediye = {
                    value: c.user_id,
                    text: c.user_adsoyad
                }
                data.push(belediye);
                return belediye;
            })
            return data;
        }
    },

    mounted(){
        this.markers = this._markers;
    },

    watch: {
        _markers(a){
            this.markers = a;
        },
        secilenBelediye(a){
            if(a){
                this.secilenBolge = null;
            }
        },
        secilenBolge(a){
            if(a){
                this.secilenBelediye = null;
            }
        }
    }

}
</script>

<style scoped>
.no-radius{
    border-radius: 0;
}
.no-padding{
    padding: 0!important;
}
</style>